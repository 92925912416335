import React, { useState, useEffect } from 'react';
import { Modal, Segment, Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DetailsLine from './DetailsLine';
import helpers, { cancelToken } from '../../services/helpers';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

const isJSON = str => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}
const statusIcon = status => {
    switch (status) {
        case false:
            return (<Icon color='yellow' name='exclamation triangle' style={{ 'marginRight': '10px' }} />)
        case true:
            return (<Icon color='green' name='check circle' style={{ 'marginRight': '10px' }} />)
        default:
            return (<Icon color='red' name='question circle' style={{ 'marginRight': '10px' }} />)
    }
}

const HistoricData = ({ historicData }) => {
    if (historicData !== undefined && historicData.length > 0 && historicData !== null) {
        return historicData.map((val, index) => {
            return (
                <div id={index} key={index}>
                    <DetailsLine>
                        <strong style={{ 'width': '200px' }}>Date</strong>
                        <span>{(val.date || 'N/A')}</span>
                    </DetailsLine>
                    <DetailsLine>
                        <strong style={{ 'width': '200px' }}>Status</strong>{statusIcon(val.status)}
                    </DetailsLine>
                    <DetailsLine>
                        <strong style={{ 'width': '200px' }}>Status Facial</strong>{statusIcon(val.facialStatus)}
                    </DetailsLine>
                    <DetailsLine>
                        <strong style={{ 'width': '200px' }}>Status_identity</strong>{statusIcon(val.identityStatus)}
                    </DetailsLine>
                    <DetailsLine>
                        <strong style={{ 'width': '200px' }}>Status_watchlist</strong>{statusIcon(val.watchlistStatus)}
                    </DetailsLine>
                    <hr></hr>
                </div>
            );
        })
    } else {
        return (
            <div>
                <span>No data to display</span>
            </div>
        )
    }
}

const ModalIDV = ({ /*idv, */report }) => {

    const [open, setOpen] = useState(false);
    const [historicData, setHistoricData] = useState([]);
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const { t } = useTranslation();
    let reportKYCHistoric;
    let uuid;

    if (isJSON(report)) {
        try {
            reportKYCHistoric = JSON.parse(report).breakdown;
            // uuid = JSON.parse(report).wallet;
        } catch (e) {
            reportKYCHistoric = false;
        }
    }

    function search() {
        const source = cancelToken.source();
        const promise = helpers
            .getKYCHistoric(source, uuid, dateFrom, dateTo)
            .then(function (response) {
                setHistoricData(response.data);
            })
            .catch(console.error);
    }

    const handleDateFromChange = date => {
        let formattedDate = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
        setDateFrom(formattedDate);
    };

    const handleDateToChange = date => {
        let formattedDate = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
        setDateTo(formattedDate);
    };


    return (
        <Modal
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            size="small"
            trigger={<Button style={{ position: 'absolute', right: '1.5rem', top: '0.85rem', margin: 0 }}>KYC Historic</Button>}>
            <Modal.Header>KYC Historic Reports</Modal.Header>
            <Modal.Content>
                <h3>Select date range</h3>
                <Segment vertical>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="dateFrom"
                            label="From"
                            value={dateFrom}
                            onChange={handleDateFromChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />

                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="dateTo"
                            label="To"
                            value={dateTo}
                            onChange={handleDateToChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <Button
                        style={{ backgroundColor: '#fa4261', color: 'white', height: "40px", marginTop: "3%", marginLeft: "21%" }}
                        icon='search'
                        content='Search'
                        onClick={search} />
                </Segment>
                <Segment vertical>
                    <HistoricData historicData={historicData}></HistoricData>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button style={{ backgroundColor: '#fa4261', color: 'white', marginRight: '1%' }} icon='check' content={t`popup_close`} onClick={() => setOpen(false)} />
            </Modal.Actions>
        </Modal>
    );
};

export default ModalIDV;
